// TaxManagementClientGroupDetails
import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import BusinessIcon from "@material-ui/icons/Business";
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { Link, Redirect } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import AntSwitch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

// import Chart from "react-google-charts";

// import SessionValidity from '../SessionValidity';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  clientslistLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
    marginLeft: "-10%",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    // position: 'absolute',
    // width: 500,
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    position: 'absolute',
    width: 500,
    height: 630,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top:'6%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    // padding: theme.spacing(2, 4, 3),  
    padding: theme.spacing(2, 4, 3),
    width:'100%',
  },  
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
}));

export default function TaxManagementClientGroupDetails(props) {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  // const [selected_group_for_details, setselected_group_for_details] = React.useState(JSON.parse(localStorage.getItem('selected_group_for_details')));
  const [selected_group_for_details, setselected_group_for_details] = React.useState({});
  const [GroupDetails, setGroupDetails] = React.useState([]);
  const [RedirectClientDetails, setRedirectClientDetails] = React.useState(false);

  const [Open, setOpen] = React.useState(false);
  const [ClientList, setClientList] = React.useState([
    {
      name : 'Client 1',
      pan : 'AAAAA1234A',
      is_selected : false,
    },
    {
      name : 'Client 2',
      pan : 'BBBBB1234B',
      is_selected : true,
    }
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    setShowProcedure(true);
    let selected_group_for_details = JSON.parse(localStorage.getItem('selected_group_for_details'));
    let selected_group_for_details_id = selected_group_for_details.client_group_id;
    console.log("selected_group_for_details,selected_group_for_details_id", selected_group_for_details, selected_group_for_details_id);

    handleRefreshGroupDetails();

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'client_group_id': selected_group_for_details_id,
        'device_token': device_token, 'session_id': session_id,
        'business_id': business_id
      })
    };
    // fetch(process.env.REACT_APP_GET_GROUP_CLIENTS, requestOptions)
    fetch(process.env.REACT_APP_GET_GROUP_CLIENTS_NEW, requestOptions)
      .then((Response) => Response.json())
      .then(async (GroupDetails) => {
        setShowProcedure(false);
        console.log("GroupDetails from api", GroupDetails);
        if (GroupDetails.success == true) {
          setGroupDetails(GroupDetails.data.group_clients);
        }
        else if (GroupDetails.success == false) {
          setGroupDetails([]);
          Swal.fire({
            icon: 'error',
            text: GroupDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });


  }, []);

  const handleShowClientDetails = async (item) => {
    console.log("handleShowClientDetails item", item)

    // await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(item));
    await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(item.client_id));
    await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantClientGroup"));

    setRedirectClientDetails(true);
  }

  const handleRefreshGroupDetails = () => {
    let selected_group_for_details = JSON.parse(localStorage.getItem('selected_group_for_details'));
    let selected_group_for_details_id = selected_group_for_details.client_group_id;
    console.log("selected_group_for_details,selected_group_for_details_id", selected_group_for_details, selected_group_for_details_id);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS , requestOptions)
            .then((Response) => Response.json())
            .then( async(businessClientGroupDetails) => {
              setShowProcedure(false);
              console.log("businessClientGroupDetails from api",businessClientGroupDetails);
              if(businessClientGroupDetails.success == true)
              {
                // setBusinessClientGroupDetails(businessClientGroupDetails.data.client_groups);
                businessClientGroupDetails.data.client_groups.map((i, index)=>{
                  if( i.client_group_id === selected_group_for_details_id ){
                    setselected_group_for_details(i)
                  }
                })  
              }
              else if(businessClientGroupDetails.success == false){
                setselected_group_for_details({});
              }
            })
            // .catch(err => {
            //   setShowProcedure(false);
            //   Swal.fire({
            //     icon: 'error',
            //     text: "Server Error. Please try again.",
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            //   })
            // });
  }

  const toggleAddClientToGroup = async(checked, item) => {
    console.log("toggleAdd ClientToGroup item", checked,item)

    let count = 0;
    GroupDetails.map((item, index)=>{
      console.log("item in grp clients toggle,item",item)
      if( item.is_active == true ){
        count = count + 1;
      }
    })
    console.log("count is", count)

    // if ( count <= 1 ){
    //   if( checked == false ){
    //     Swal.fire({
    //       icon: 'error',
    //       // text: "Cannot remove last client",
    //       text: "Group must have atleast one client",
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'OK',
    //       timer: 5000,
    //     })
    //   }
    //   else if( checked == true ){
    //     setGroupDetails(
    //       GroupDetails.map((e,i)=>{
    //         if(e.client_id === item.client_id){
    //           e.is_active = !e.is_active
    //         }
    //         return e
    //       })
    //     )
    //     handleAddClientsToGroupSubmit()
    //   }
    // }
    // else if ( count > 1 ){
      setGroupDetails(
        GroupDetails.map((e,i)=>{
          if(e.client_id === item.client_id){
            e.is_active = !e.is_active
          }
          return e
        })
      )
      handleAddClientsToGroupSubmit()
      // handleRefreshGroupDetails()
    // }

    
    // setGroupDetails(
    //   GroupDetails.map((e,i)=>{
    //     if(e.client_id === item.client_id){
    //       e.is_active = !e.is_active
    //     }
    //     return e
    //   })
    // )
    // handleAddClientsToGroupSubmit()
  }

  const AddClientsToGroupOpen = async () => {
    console.log("AddClientsToGroupOpen clicked",GroupDetails.length)
    if( GroupDetails.length === 0 ){
      Swal.fire({
        // icon: 'error',
        // text: "Cannot remove last client",
        text: "There is no registered client to add in group.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
        timer: 5000,
      })
    }
    else if( GroupDetails.length > 0 ){
      setOpen(true);
    }
    
  }

  const AddClientsToGroupClose = async () => {
    setOpen(false);
  }

  const handleAddClientsToGroupSubmit = async () => {
    setShowProcedure(true);
    let selectedClientArray = []
    GroupDetails && GroupDetails.map((item,index)=>{
      console.log("group details in submit is",item)
      if(item.is_active == true){
        // selectedClientArray = selectedClientArray.concat(item.user_id)
        selectedClientArray = selectedClientArray.concat(item.client_id)
      }
    })
    console.log("selectedClientArray after push is",selectedClientArray)

    let selected_group_for_details = JSON.parse(localStorage.getItem('selected_group_for_details'));
    let selected_group_for_details_id = selected_group_for_details.client_group_id;
    console.log("selected_group_for_details,selected_group_for_details_id", selected_group_for_details, selected_group_for_details_id);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'group_clients': selectedClientArray, 'group_id': selected_group_for_details_id,
        'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
    };
    // fetch( process.env.REACT_APP_UPDATE_GROUP_CLIENTS , requestOptions)
    fetch( process.env.REACT_APP_UPDATE_GROUP_CLIENTS_NEW , requestOptions)
      .then((Response) => Response.json())
      .then( async(updateGroupClients) => {
        console.log("updateGroupClients response is",updateGroupClients)
        setShowProcedure(false);
        if(updateGroupClients.success == true){
          setOpen(true);
          handleRefreshGroupDetails()
          // Swal.fire({
          //   icon: 'success',
          //   text: "Client's added in a group successfully",
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'OK',
          //   timer: 5000,
          // })
        }
        else if(updateGroupClients.success == false){
          Swal.fire({
            icon: 'error',
            text: updateGroupClients.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
          setOpen(true);
          
        }
      })
      .catch(err => {
        setShowProcedure(false);
        setOpen(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }


  const body = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Select Clients</h2>
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <Grid>
          {console.log("GroupDetails.length",GroupDetails.length)}
          {
            GroupDetails && GroupDetails.map((item, index)=>(
              console.log("item in clientlist map is", item),
              <Grid container item xs={12}>
                <Grid item xs={1} style={{padding: 10}}>
                  <input
                    style={{cursor:'pointer'}}
                    type="checkbox"
                    checked={item.is_active}
                    onChange={e => toggleAddClientToGroup(e.target.checked, item)}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Grid item xs={11} style={{ fontSize: 15, color:'black', padding: 10 }}>
                    {item.full_name}
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          onClick={handleAddClientsToGroupSubmit}
          >
            Add
          </Button> */}
           <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={AddClientsToGroupClose}
            >
              Ok
            </Button>
          {/* <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={AddClientsToGroupClose}
          >
            Cancel
          </Button> */}
        </div>
      </div>
    </div>
  )

  const handleDeactivteGroup = (item) => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleDeactivteGroup",item)
    setselected_group_for_details({
      ...selected_group_for_details,
      // selected_group_for_details.map((i, index)=>{
        // if(i.client_group_id == item.client_group_id){
        //   console.log("client_group_id matched",i.client_group_id)
          is_active : !selected_group_for_details.is_active
        //  }
        // return i;
      // })
  })

    let changeGrpIsActive = {
      client_group_id: item.client_group_id,
      name: item.name,
      shortcode: item.shortcode,
      description: item.description,
      is_active: !item.is_active, 
      business_id: business_id,
      device_token: device_token,
      session_id: session_id,
    }

    console.log("changeGrpIsActive", changeGrpIsActive)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(changeGrpIsActive)
    };
    fetch( process.env.REACT_APP_CREATE_CLIENT_GROUP , requestOptions)
      .then((Response) => Response.json())
      .then(groupIsActive => {
        console.log("groupIsActive are",groupIsActive);

      })
  }


  if (RedirectClientDetails == true) {
    return <Redirect to={`/TaxManagementClientDetailsUI`} />
  }
  else {
    return (
      console.log("GroupDetails", GroupDetails),
      console.log("selected_group_for_detail",selected_group_for_details),

      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
        </modal> : null}
        <div className={classes.wrapper}>
          <Grid container>
            <Button href="/TaxManagementClientGroups" className={classes.backButton}>
              <span className={classes.backArrow}>
                <ArrowBackIcon fontSize="small" />
              </span>
              <FormattedMessage id="backLabel" />
            </Button>
          </Grid>
          <Grid className={classes.pageHeaderContainer} >
            <Grid container item xs={12} style={{ marginTop: '2%' }}>
              <Grid item xs={8} className={classes.pageHeaderLabel} >
                Group Details
              </Grid>
              <Grid item xs={4}>
                <Grid
                  onClick={() => AddClientsToGroupOpen()}
                  style={{ color: 'blue', marginLeft: '28%', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer' }}
                >
                  Add Clients to Group
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{
              marginTop: '2%', fontSize: '15px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.64)',
              textAlign: "left",
            }}>
              Name: &nbsp; {selected_group_for_details && selected_group_for_details.name}
            </Grid>
            <Grid item xs={12} style={{
              marginTop: '0.5%', fontSize: '15px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.64)',
              textAlign: "left",
            }}>
              Shortcode: &nbsp; {selected_group_for_details && selected_group_for_details.shortcode}
            </Grid>
            <Grid item xs={12} style={{
              marginTop: '0.5%', fontSize: '15px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.64)',
              textAlign: "left",
            }}>
              Description: &nbsp; {selected_group_for_details && selected_group_for_details.description}
            </Grid>
            <Grid item xs={12} style={{
              marginTop: '0.5%', fontSize: '15px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.64)',
              textAlign: "left",
            }}>
              Total Clients: &nbsp; {selected_group_for_details && selected_group_for_details.total_clients}
            </Grid>

            <Grid container item xs={12} style={{ marginTop: '0.5%', textAlign: "left",}}>
              <Grid>
              {
                (selected_group_for_details && selected_group_for_details.is_active === true) ?
                  (
                    <Grid style={{ color: "green",fontSize: '15px', fontWeight: 600 }}>Active</Grid>
                  )
                  : (
                    // <Grid style={{ color: "red", fontSize: '15px', fontWeight: 600 }}>Inactive</Grid>
                    <></>
                  )
              }
              {
                (selected_group_for_details && selected_group_for_details.is_active === false) ?
                  (
                    <Grid style={{ color: "red", fontSize: '15px', fontWeight: 600 }}>Inactive</Grid>
                  )
                  : (
                    // <Grid style={{ color: "red", fontSize: '15px', fontWeight: 600 }}>Inactive</Grid>
                    <></>
                  )
              }
              </Grid>
              {
                (selected_group_for_details && selected_group_for_details.is_active === true ) ?
                (
                  <Grid>
                    <Tooltip
                      title={ selected_group_for_details && selected_group_for_details.is_active === true ? "Switch to Inactivate Group" : "Switch to Activate Group"}
                      placement="left-start"
                    >
                      <AntSwitch 
                        checked={selected_group_for_details && selected_group_for_details.is_active} 
                        onChange={() => handleDeactivteGroup(selected_group_for_details)} 
                        size="small" 
                        name="selected_group_for_details.is_active" 
                        value="selected_group_for_details.is_active"
                        color="primary"
                      />
                    </Tooltip>
                  </Grid>
                ):
                (
                  
                  <></>
                )
              }

              {
                (selected_group_for_details && selected_group_for_details.is_active === false ) ?
                (
                 <Grid>
                    <Tooltip
                      title={ selected_group_for_details && selected_group_for_details.is_active === true ? "Switch to Inactivate Group" : "Switch to Activate Group"}
                      placement="left-start"
                    >
                      <AntSwitch 
                        checked={selected_group_for_details && selected_group_for_details.is_active} 
                        onChange={() => handleDeactivteGroup(selected_group_for_details)} 
                        size="small" 
                        name="selected_group_for_details.is_active" 
                        value="selected_group_for_details.is_active"
                        color="primary"
                      />
                    </Tooltip>
                  </Grid>
                ):
                (
                  <></>
                )
              }
              
            </Grid>
          </Grid>
          <Paper className={classes.paper} style={{ marginTop: '-2%' }}>
            <Grid className={classes.clientslistLabel}>Clients List </Grid>
            {
              GroupDetails && GroupDetails.filter(item => item.is_active == true ).map((item, index) => (
                <Grid style={{ marginTop: '2%', padding: 10, backgroundColor: '#cde6ac' }}>
                  <Grid style={{ color: 'black', fontSize: '15px', marginLeft: '1%', cursor: 'pointer' }}
                    onClick={() => handleShowClientDetails(item)}
                  >
                    {index + 1}. &nbsp;{item.full_name}
                  </Grid>
                </Grid>
              ))
            }
          </Paper>
        </div>
        <Modal
          open={Open}
          onClose={AddClientsToGroupClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>
    );
  }
}